(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-grid-flexible/assets/javascripts/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-grid-flexible/assets/javascripts/handlebars-helpers.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const CSS_ITEM_DESKTOP_LANDSCAPE_L_643X482 = 'item-size-desktop-landscape-large';
const CSS_ITEM_DESKTOP_LANDSCAPE_L_PICTURE_643X482 = 'item-size-desktop-landscape-large item-desktop-picture-only';
const CSS_ITEM_DESKTOP_LANDSCAPE_L_CONTENT_643X482 = 'item-size-desktop-landscape-large item-desktop-content-only';
const CSS_ITEM_DESKTOP_LANDSCAPE_M_420X315 = 'item-size-desktop-landscape-medium';
const CSS_ITEM_DESKTOP_LANDSCAPE_S_309X232 = 'item-size-desktop-landscape-small';
const CSS_ITEM_DESKTOP_PORTRAIT_L_420X631 = 'item-size-desktop-portrait-large';
const CSS_ITEM_DESKTOP_PORTRAIT_M_309X464 = 'item-size-desktop-portrait-medium';
const CSS_ITEM_DESKTOP_PORTRAIT_L_FOCUS_420X654 = 'item-size-desktop-portrait-large item-desktop-is-in-focus';
const CSS_ITEM_DESKTOP_PORTRAIT_M_FOCUS_309X488 = 'item-size-desktop-portrait-medium item-desktop-is-in-focus';
const CSS_ITEM_TABLET_LANDSCAPE_L_476X357 = 'item-size-tablet-landscape-large';
const CSS_ITEM_TABLET_LANDSCAPE_L_PICTURE_476X357 = 'item-size-tablet-landscape-large item-tablet-picture-only';
const CSS_ITEM_TABLET_LANDSCAPE_L_CONTENT_476X357 = 'item-size-tablet-landscape-large item-tablet-content-only';
const CSS_ITEM_TABLET_LANDSCAPE_M_309X232 = 'item-size-tablet-landscape-medium';
const CSS_ITEM_TABLET_LANDSCAPE_S_226X170 = 'item-size-tablet-landscape-small-or-medium';
const CSS_ITEM_TABLET_PORTRAIT_L_309X464 = 'item-size-tablet-portrait-large';
const CSS_ITEM_TABLET_PORTRAIT_M_226X339 = 'item-size-tablet-portrait-medium-or-large';
if (typeof exports === 'object') {
  registerHelpers(require('hbs'), {
    isServerSide: true
  });
} else {
  registerHelpers(Handlebars, {
    isServerSide: false
  });
}

function registerHelpers(hbs, _ref) {
  let {
    isServerSide
  } = _ref;
  const _renderWithCompiledTemplate = (target, model) => {
    const compiledTemplate = isServerSide ? hbs.cache["components-lb_ui-products_grid_flexible-".concat(target)] :
    svs.lb_ui.products_grid_flexible.templates[target];
    return compiledTemplate ? compiledTemplate(model) : '';
  };
  hbs.registerHelper('products-grid-flexible', function productsGridFlexibleHelper(target, options) {
    const itemData = collectGridItemsData(target, options, this);
    if (target !== 'grid' || !itemData || itemData.length === 0) {
      return '';
    }
    const itemsSetup = getGridItemsSetup({
      itemCount: itemData.length,
      hasOptionalLayout: options.hash.layout === 'optional'
    });
    const _renderSingleChild = setup => {
      const {
        itemPos: _itemSetupPos,
        cssClasses: _itemSetupClasses
      } = setup;
      const {
        children: _children,
        optionsHash
      } = itemData[_itemSetupPos - 1] || {};
      const itemModel = _objectSpread(_objectSpread({}, optionsHash), {}, {
        _children,
        _itemSetupPos,
        _itemSetupClasses
      });
      return _renderWithCompiledTemplate('item', itemModel) || '';
    };
    const gridModel = _objectSpread(_objectSpread({}, options.hash), {}, {
      _hasNav: itemData.length >= 4,
      _hasNavWithTwoButtons: itemData.length >= 4,
      _hasNavWithThreeButtons: itemData.length >= 7,
      _itemCount: itemData.length,
      _children: itemsSetup.map(_renderSingleChild).join('')
    });
    return _renderWithCompiledTemplate('grid', gridModel) || '';
  });
}

function collectGridItemsData(target, options, hbsContext) {
  if (target === 'grid') {
    options.data._gridFlexibleItemData = [];
    options.fn(hbsContext);
    const itemData = options.data._gridFlexibleItemData;
    delete options.data._gridFlexibleItemData;
    return itemData;
  }
  if (target === 'item') {
    options.data._gridFlexibleItemData.push({
      children: options.fn(hbsContext),
      optionsHash: _objectSpread({}, options.hash)
    });
  }
  return null;
}

function getGridItemsSetup(_ref2) {
  let {
    itemCount,
    hasOptionalLayout
  } = _ref2;
  const _iterate = function (startPos, endPos) {
    const result = [];
    for (var _len = arguments.length, cssClasses = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      cssClasses[_key - 2] = arguments[_key];
    }
    for (let itemPos = startPos; itemPos <= endPos; itemPos++) {
      result.push({
        itemPos,
        cssClasses: cssClasses.join(' ')
      });
    }
    return result;
  };
  const _wrap = function (itemPos) {
    for (var _len2 = arguments.length, cssClasses = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      cssClasses[_key2 - 1] = arguments[_key2];
    }
    return {
      itemPos,
      cssClasses: cssClasses.join(' ')
    };
  };
  switch (itemCount) {
    case 1:
      return [_wrap(1, CSS_ITEM_DESKTOP_LANDSCAPE_L_PICTURE_643X482, CSS_ITEM_TABLET_LANDSCAPE_L_PICTURE_476X357), _wrap(1, CSS_ITEM_DESKTOP_LANDSCAPE_L_CONTENT_643X482, CSS_ITEM_TABLET_LANDSCAPE_L_CONTENT_476X357)];
    case 2:
      return _iterate(1, 2, CSS_ITEM_DESKTOP_LANDSCAPE_L_643X482, CSS_ITEM_TABLET_LANDSCAPE_L_476X357);
    case 3:
      return hasOptionalLayout ? _iterate(1, 3, CSS_ITEM_DESKTOP_PORTRAIT_L_420X631, CSS_ITEM_TABLET_PORTRAIT_L_309X464) : _iterate(1, 3, CSS_ITEM_DESKTOP_LANDSCAPE_M_420X315, CSS_ITEM_TABLET_LANDSCAPE_M_309X232);
    case 4:
      return hasOptionalLayout ? _iterate(1, 4, CSS_ITEM_DESKTOP_PORTRAIT_M_309X464, CSS_ITEM_TABLET_PORTRAIT_M_226X339) : _iterate(1, 4, CSS_ITEM_DESKTOP_LANDSCAPE_S_309X232, CSS_ITEM_TABLET_LANDSCAPE_S_226X170);
    case 5:
      return hasOptionalLayout ? [..._iterate(1, 4, CSS_ITEM_DESKTOP_LANDSCAPE_M_420X315, CSS_ITEM_TABLET_PORTRAIT_M_226X339), _wrap(5, CSS_ITEM_DESKTOP_PORTRAIT_L_FOCUS_420X654, CSS_ITEM_TABLET_PORTRAIT_M_226X339)] : [_wrap(1, CSS_ITEM_DESKTOP_PORTRAIT_L_FOCUS_420X654, CSS_ITEM_TABLET_LANDSCAPE_S_226X170), ..._iterate(2, 5, CSS_ITEM_DESKTOP_LANDSCAPE_M_420X315, CSS_ITEM_TABLET_LANDSCAPE_S_226X170)];
    case 6:
      return hasOptionalLayout ? _iterate(1, 6, CSS_ITEM_DESKTOP_LANDSCAPE_M_420X315, CSS_ITEM_TABLET_PORTRAIT_M_226X339) : _iterate(1, 6, CSS_ITEM_DESKTOP_LANDSCAPE_M_420X315, CSS_ITEM_TABLET_LANDSCAPE_S_226X170);
    case 7:
      return hasOptionalLayout ? [..._iterate(1, 6, CSS_ITEM_DESKTOP_LANDSCAPE_S_309X232, CSS_ITEM_TABLET_PORTRAIT_M_226X339), _wrap(7, CSS_ITEM_DESKTOP_PORTRAIT_M_FOCUS_309X488, CSS_ITEM_TABLET_PORTRAIT_M_226X339)] : [_wrap(1, CSS_ITEM_DESKTOP_PORTRAIT_M_FOCUS_309X488, CSS_ITEM_TABLET_LANDSCAPE_S_226X170), ..._iterate(2, 7, CSS_ITEM_DESKTOP_LANDSCAPE_S_309X232, CSS_ITEM_TABLET_LANDSCAPE_S_226X170)];
    case 8:
      return hasOptionalLayout ? _iterate(1, 8, CSS_ITEM_DESKTOP_LANDSCAPE_S_309X232, CSS_ITEM_TABLET_PORTRAIT_M_226X339) : _iterate(1, 8, CSS_ITEM_DESKTOP_LANDSCAPE_S_309X232, CSS_ITEM_TABLET_LANDSCAPE_S_226X170);
    default:
      break;
  }
  return [];
}

 })(window);