(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-grid-flexible/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-grid-flexible/assets/javascripts/init.js');
"use strict";


const {
  initGrid
} = svs.components.lbUi.productsGridFlexible;
const init = widgetElement => {
  if (widgetElement) {
    const allGridElements = widgetElement.getElementsByClassName('js-products-grid-flexible');
    for (let i = 0; i < allGridElements.length; i++) {
      initGrid(allGridElements[i]);
    }
  }
};
setGlobal('svs.components.lbUi.productsGridFlexible.init', init);

 })(window);