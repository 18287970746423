(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-grid-flexible/assets/javascripts/init-grid.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-grid-flexible/assets/javascripts/init-grid.js');
"use strict";



const log = new svs.core.log.Logger('components:lb-ui:products-grid-flexible');
const mockableImports = Object.seal({
  scrollHorizontally: svs.components.lbUtils.scrollSmoothly.scrollHorizontally,
  NavigationIndicator: svs.ui.NavigationIndicator
});

function initGrid(gridElement) {
  if (gridElement.dataset.initialized) {
    return;
  }
  gridElement.dataset.initialized = 'true';
  const itemsContainer = gridElement.getElementsByClassName('js-products-grid-flexible-items')[0];
  const navContainer = gridElement.getElementsByClassName('js-products-grid-flexible-navigation')[0];
  if (!itemsContainer || !navContainer) {
    return;
  }
  for (let i = 0; i < navContainer.children.length; i++) {
    setupScrolling({
      navElement: navContainer.children[i],
      itemsContainer
    });
  }
}

function setupScrolling(_ref) {
  let {
    navElement,
    itemsContainer
  } = _ref;
  let currPageIndex = 0;
  const latestEvents = createLatestEventsTracker();
  navElement.addEventListener('click', () => latestEvents.onNavClick());
  const navObject = new mockableImports.NavigationIndicator({
    el: navElement,
    onChange(index) {
      latestEvents.onNavChange();
      if (latestEvents.hasScrollBeforeChange) {
        return;
      }
      const targetScrollLeft = getScrollLeftForEverySnapAlignTarget(itemsContainer)[index];
      if (targetScrollLeft !== null && index !== currPageIndex) {
        currPageIndex = index;
        mockableImports.scrollHorizontally(itemsContainer, targetScrollLeft, {
          hasScrollSnap: true
        });
      }
    }
  });
  const _updateNavIndicator = newScrollLeft => {
    let indexOfClosestSnapAlignItem = null;
    let minDistance = null;
    const everySnapTargetScrollLeft = getScrollLeftForEverySnapAlignTarget(itemsContainer);
    for (let i = 0; i < everySnapTargetScrollLeft.length; i++) {
      const currDistance = Math.abs(everySnapTargetScrollLeft[i] - newScrollLeft);
      if (minDistance === null || currDistance < minDistance) {
        minDistance = currDistance;
        indexOfClosestSnapAlignItem = i;
      }
    }
    if (indexOfClosestSnapAlignItem !== null && indexOfClosestSnapAlignItem !== currPageIndex) {
      currPageIndex = indexOfClosestSnapAlignItem;
      navObject.setCurrentItem(indexOfClosestSnapAlignItem);
    }
  };
  itemsContainer.addEventListener('scroll', getHandlerForDebouncedEvents({
    timeoutMS: 300,
    onFirstEvent() {
      const isRelatedNavElementVisible = window.getComputedStyle(navElement).display !== 'none';
      if (isRelatedNavElementVisible) {
        latestEvents.onScrollStart();
      }
    },
    onEveryEvent(event) {
      if (latestEvents.hasScrollBeforeChange) {
        _updateNavIndicator(event.target.scrollLeft);
      }
    },
    onFinalEvent(event) {
      const isRelatedNavElementVisible = window.getComputedStyle(navElement).display !== 'none';
      if (isRelatedNavElementVisible) {
        latestEvents.onScrollEnd();
        _updateNavIndicator(event.target.scrollLeft);
      }
    },
    onFirstError(error) {
      log.error('Error when scrolling items-container', error);
    }
  }));
}

function createLatestEventsTracker() {
  return Object.seal({
    hasClickSomewhereInNavElement: false,
    hasChangeBeforeScroll: false,
    hasScrollBeforeChange: false,
    isScrolling: false,
    onScrollStart() {
      this.isScrolling = true;
      if (!this.hasClickSomewhereInNavElement && !this.hasChangeBeforeScroll) {
        this.hasScrollBeforeChange = true;
      }
    },
    onScrollEnd() {
      this.isScrolling = false;
      this.resetIfNotScrolling(50);
    },
    onNavClick() {
      if (!this.hasScrollBeforeChange) {
        this.hasClickSomewhereInNavElement = true;
      }
      this.resetIfNotScrolling(200);
    },
    onNavChange() {
      if (!this.hasScrollBeforeChange) {
        this.hasChangeBeforeScroll = true;
      }
      this.resetIfNotScrolling(200);
    },
    resetTimeoutHandle: null,
    resetIfNotScrolling(timeoutMS) {
      if (this.isScrolling) {
        return;
      }
      if (this.resetTimeoutHandle) {
        clearTimeout(this.resetTimeoutHandle);
      }
      this.resetTimeoutHandle = setTimeout(() => {
        if (this.isScrolling) {
          return;
        }
        this.resetTimeoutHandle = null;
        this.hasClickSomewhereInNavElement = false;
        this.hasChangeBeforeScroll = false;
        this.hasScrollBeforeChange = false;
      }, timeoutMS);
    }
  });
}

function getHandlerForDebouncedEvents(inputBag) {
  const {
    timeoutMS,
    onFirstEvent = null,
    onEveryEvent = null,
    onFinalEvent = null,
    onFirstError = null
  } = inputBag;
  let timeoutHandle = null;
  let errorCount = 0;
  return event => {
    if (timeoutHandle === null) {
      try {
        if (onFirstEvent) {
          onFirstEvent(event);
        }
      } catch (error) {
        errorCount++;
        if (errorCount === 1 && onFirstError) {
          onFirstError(error);
        }
      }
    } else {
      clearTimeout(timeoutHandle);
    }
    try {
      if (onEveryEvent) {
        onEveryEvent(event);
      }
    } catch (error) {
      errorCount++;
      if (errorCount === 1 && onFirstError) {
        onFirstError(error);
      }
    }
    timeoutHandle = setTimeout(() => {
      timeoutHandle = null;
      try {
        if (onFinalEvent) {
          onFinalEvent(event);
        }
      } catch (error) {
        errorCount++;
        if (errorCount === 1 && onFirstError) {
          onFirstError(error);
        }
      }
      errorCount = 0;
    }, timeoutMS);
  };
}

function getScrollLeftForEverySnapAlignTarget(itemsContainer) {
  if (!itemsContainer) {
    return [];
  }
  const itemsContainerOuterWidth = itemsContainer.getBoundingClientRect().width;
  const results = [];
  for (let i = 0; i < itemsContainer.children.length; i++) {
    const itemElement = itemsContainer.children[i];
    const itemDimensionsInParent = itemElement.getBoundingClientRect();
    const itemLeft = itemDimensionsInParent.left + itemsContainer.scrollLeft;
    const scrollSnapAlign = itemElement.dataset.backupStyle || window.getComputedStyle(itemElement).scrollSnapAlign;
    if (scrollSnapAlign === 'start') {
      results.push(itemLeft);
    }
    if (scrollSnapAlign === 'center') {
      const itemCenter = itemLeft + itemDimensionsInParent.width / 2;
      results.push(itemCenter - itemsContainerOuterWidth / 2);
    }
    if (scrollSnapAlign === 'end') {
      results.push(itemLeft + itemDimensionsInParent.width - itemsContainerOuterWidth);
    }
  }
  return results;
}

setGlobal('svs.components.lbUi.productsGridFlexible.initGrid', initGrid);
setGlobal('svs.components.lbUi.productsGridFlexible._testInternals.initGrid', {
  getMockableImports: () => mockableImports
});

 })(window);